import React, { useEffect, useState } from 'react'
import * as styles from './MobileNavigation.module.scss'
import { Link } from 'gatsby'
import classNames from 'classnames'
import CheveronDown from '../../images/svg-components/CheveronDown'
import Lock from '../../images/svg-components/Lock'

const IS = 'is-IS'
const EN = 'en-US'

export default ({ displayMobileMenu, currentLang, currentPageId, data }) => {
  const langData = {
    [IS]: {
      'prefix': '/',
      'otherLang': 'en-US',
      'nicename': 'Íslenska',
      'menu': data.isMenu,
      'otherLangpages': data.enPages,
      'myPagesCaption': 'Mínar síður',
      'myPagesUrl': 'https://book.herjolfur.is/innskraning'
    },
    [EN]: {
      'prefix': '/en/',
      'otherLang': 'is-IS',
      'nicename': 'English',
      'menu': data.enMenu,
      'otherLangpages': data.isPages,
      'myPagesCaption': 'Login',
      'myPagesUrl': 'https://book.herjolfur.is/login'
    }
  }

  const currentLangData = currentLang ? langData[currentLang] : langData[EN]
  const pageUrl = slug => {
    return `${currentLangData.prefix}${slug}`
  }

  const [ { navigationItems: rawNavItems } ] = currentLangData.menu.nodes
  const navigationItems = rawNavItems.map(item => ({
    url: pageUrl(item.pageReference.slug),
    title: item.title,
    childItems: item.subItems.map(({ title, pageReference: { slug } }) => ({
      url: pageUrl(slug),
      title
    }))
  }))

  const containerClasses = classNames('d-none', 'd-lg-none', styles.container, {
    'd-block': displayMobileMenu
  })
  const navClasses = classNames(styles.nav, 'align-self-center')

  const [activeChapter, setActiveChapter] = useState(null)

  const toggleChapter = chapterId => {
    setActiveChapter(activeChapter === chapterId ? null : chapterId)
  }

  useEffect(() => (
    setActiveChapter(null)
  ), [displayMobileMenu])

  return (
    <div className={containerClasses}>
      <nav className={navClasses}>
        <ul className={styles.navList}>
          {navigationItems && navigationItems.reverse().map((item, index) => (
            <li key={index}>
              <div className={styles.navItem}>
                <button
                  onClick={() => toggleChapter(index)}
                  className={classNames(styles.navItemChapter, activeChapter === index && styles.navItemChapter__open)}
                >
                  <h1>{item.title}</h1>
                  <CheveronDown/>
                </button>
                <ul
                  className={classNames(styles.subchapters, activeChapter === index && styles.subchapters__open)}
                >
                  {
                    item.childItems.length > 0 && item.childItems.map(({ url, title }) => (
                      <li>
                        <Link href={url}>
                          {title}
                        </Link>
                      </li>
                    ))
                  }
                </ul>
              </div>
            </li>
          ))}
          <li>
            <Link
              href={currentLangData.myPagesUrl}
              target='_blank'
              rel='no-follow'
              className={styles.myPagesChapter}
            >
              <div>
                <Lock/>
                <h1>{currentLangData.myPagesCaption}</h1>
              </div>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}
