import React from 'react'
import * as styles from './Navigation.module.scss'
import { Link } from 'gatsby'
import classNames from 'classnames'
import Globe from '../../images/svg-components/Globe.js'
import CheveronDown from '../../images/svg-components/CheveronDown.js'
import { getSisterPageUrl } from '../../utils'
import Lock from '../../images/svg-components/Lock'

const IS = 'is-IS'
const EN = 'en-US'

export default ({ currentLang, currentPageId, data }) => {
  const langData = {
    [IS]: {
      'prefix': '/',
      'otherLang': 'en-US',
      'nicename': 'ÍSL',
      'menu': data.isMenu,
      'otherLangpages': data.enPages,
      'myPagesCaption': 'Mínar síður',
      'myPagesUrl': 'https://book.herjolfur.is/innskraning'
    },
    [EN]: {
      'prefix': '/en/',
      'otherLang': 'is-IS',
      'nicename': 'ENG',
      'menu': data.enMenu,
      'otherLangpages': data.isPages,
      'myPagesCaption': 'Login',
      'myPagesUrl': 'https://book.herjolfur.is/login'
    }
  }

  const currentLangData = currentLang ? langData[currentLang] : langData[EN]

  const pageUrl = slug => {
    return `${currentLangData.prefix}${slug}`
  }

  const [ { navigationItems: rawNavItems } ] = currentLangData.menu.nodes

  const navigationItems = rawNavItems.map(item => ({
    url: pageUrl(item.pageReference.slug),
    title: item.title,
    childItems: item.subItems.map(({ title, pageReference: { slug } }) => ({
      url: pageUrl(slug),
      title
    }))
  }))

  const navClasses = classNames(
    styles.container,
    'align-self-center',
    'd-none',
    'd-md-flex',
    'align-items-stretch'
  )

  const otherLangNicename = langData[currentLangData.otherLang].nicename

  const sisterPageUrl = getSisterPageUrl(
    currentPageId,
    currentLangData.otherLangpages,
    currentLang
  )

  return (
    <nav className={navClasses}>
      {navigationItems && navigationItems.map(item => (
        <NavigationItem
          key={item.url}
          url={item.url}
          title={item.title}
          childItems={item.childItems}
        />
      ))}

      <NavigationItem>
        <div className={styles.myPages}>
          <Lock/>
          <a
            href={currentLangData.myPagesUrl}
            rel='no-follow'
          >
            {currentLangData.myPagesCaption}
          </a>
        </div>
      </NavigationItem>

      <NavigationItem
        url={sisterPageUrl}
        title={otherLangNicename}
        childItems={[
          {
            url: sisterPageUrl,
            title: otherLangNicename
          }
        ]}
      >
        <span>
          <div className={styles.globe}>
            <Globe />
          </div>
          <span className={classNames('pt-0', styles.globeText)}>{currentLangData.nicename}</span>
        </span>
      </NavigationItem>
    </nav>
  )
}

/**
 * Represents each item in the menu
 *
 * @param {props} props
 */
const NavigationItem = props => {
  const {
    url,
    title,
    children,
    childItems = [],
    ...rest
  } = props

  const content = children || (
    <button
      {...rest}
    >
      {title}
    </button>
  )

  return (
    <div className={styles.navigationItem}>
      {content}

      {childItems.length > 0 && (
        <div
          aria-hidden='true'
          className={styles.chev}
        >
          <CheveronDown />
        </div>
      )}

      {childItems.length > 0 && (
        <ul className={styles.dropdown}>
          {childItems.map(({ url, title }) => (
            <li key={url}>
              <Link
                to={url}
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
